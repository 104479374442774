const adminRouter = [
  {
    path: '/admin',
    component: () => import('@/views/Layout.vue'),
    meta: {
      role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'],
    },
    children: [
      {
        path: '',
        name: 'AdminManage',
        meta: {
          icon: 'icon-manager',
          title: '后台账号管理',
        },
        component: () => import('@/views/Admin/AdminManage.vue'),
      },
    ],
  },
]

export default adminRouter
