<template>
  <el-drawer
    :title="hintWord"
    :visible.sync="visible"
    direction="rtl"
    size="40%"
    custom-class="edit-admin-drawer"
    @close="initDrawer"
  >
    <div class="drawer-container">
      <div class="content">
        <el-form ref="editForm" :model="editForm" label-width="70px" :rules="rules">
          <el-form-item prop="username" label="登录名">
            <el-input v-model="editForm.username"></el-input>
          </el-form-item>
          <el-form-item prop="nickname" label="昵称">
            <el-input v-model="editForm.nickname"></el-input>
          </el-form-item>
          <el-form-item v-if="!isEdit" prop="password" label="密码">
            <el-input v-model="editForm.password" type="password" required></el-input>
          </el-form-item>
          <el-form-item v-else prop="password" label="密码">
            <div v-if="!isEditPassword" class="password-item">
              <span>******</span>
              <span class="text-btn" @click="isEditPassword = true">修改密码</span>
            </div>
            <el-input v-else v-model="editForm.password" type="password" required></el-input>
          </el-form-item>
          <el-form-item v-if="isEdit" prop="status" label="状态">
            <el-radio-group v-model="editForm.status">
              <el-radio v-for="(item, key) in managerStatusMap" :key="key" :label="Number(key)">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <div class="drawer-footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { createManager, updateManager, updateMy } from '@/api/admin'
import { USER_ROLES } from '@/views/config'
import _ from 'lodash'

const MANAGER_STATUS_MAP = {
  0: { label: '正常', type: '' },
  1: { label: '离职', type: 'info' },
}

export default {
  name: 'EditAdminDrawer',
  data() {
    const checkPassword = (rule, value, callback) => {
      // 新建用户 和 编辑用户密码时才需要校验
      if (!this.isEditPassword || !this.isEdit) {
        callback()
        return
      }
      if (value.length >= 8) {
        callback()
      } else {
        callback(new Error('密码不得少于8位'))
      }
    }
    return {
      isEdit: false,
      isEditCurUser: false, // 编辑当前用户信息, 不需要超管权限
      onSuccess: null,
      isEditPassword: false,
      visible: false,
      editForm: {
        username: '',
        nickname: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ validator: checkPassword, trigger: 'blur' }],
      },
    }
  },
  computed: {
    managerStatusMap: () => MANAGER_STATUS_MAP,
    managerRolesMap: () => {
      let map = {}
      for (const role in USER_ROLES) {
        map[role] = USER_ROLES[role]
        map[role].type = Number(role) === 1000 ? 'success' : ''
      }

      return map
    },
    hintWord() {
      return `${this.isEdit ? '编辑' : '创建'}${this.isEditCurUser ? '我的' : '管理员'}信息`
    },
  },
  methods: {
    open({ data = {}, isEditCurUser = false, onSuccess }) {
      this.isEdit = !!data.id && data.id !== ''
      this.isEditCurUser = isEditCurUser
      this.onSuccess = onSuccess
      this.visible = true
      this.$nextTick(() => {
        this.editForm = _.cloneDeep(data)
      })
    },
    submit() {
      this.$refs.editForm.validate(valid => {
        if (!valid) {
          this.$message.warning('请检查输入项')
          return
        }

        let api = this.isEdit ? (this.isEditCurUser ? updateMy : updateManager) : createManager
        api(this.editForm).then(() => {
          this.$message.success(`${this.hintWord}成功`)
          this.visible = false
          let editData = _.omit(this.editForm, 'password')
          this.onSuccess(editData)
        })
      })
    },
    initDrawer() {
      this.$refs.editForm.resetFields()
      this.isEditPassword = false
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-admin-drawer {
  .password-item {
    display: flex;
    align-items: center;
    .text-btn {
      margin-left: 10px;
      font-size: 12px;
    }
  }
}
</style>
