import dayjs from 'dayjs'

// 全局过滤器
const filters = {
  /**
   * 性别格式化
   * @param {string} lang 语言 cn / en
   * @return {string}
   */
  formatGender: function(value, lang = 'cn') {
    // 用户性别 0-女 1-男
    const genderMap = [
      {
        cn: '女',
        en: 'female',
      },
      {
        cn: '男',
        en: 'male',
      },
    ]

    if (value === '' || value === -1) {
      let unknown = {
        cn: '未知',
        en: 'unknown',
      }
      return unknown[lang]
    }

    return genderMap[value][lang]
  },
  /**
   * 日期格式化
   * @param {string} format 格式
   * @return {string}
   */
  formatDate: function(value, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(parseInt(value)).format(format)
  },
  /**
   * 转换货币单位 分 -> 元
   */
  formatFenToYuan: function(value) {
    return value / 100
  },
}

export default filters
