import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
import electricityRouter from './electricity'
import cdKeyRouter from './cdKey'
import betaKeyRouter from './betaKey'
import adminRouter from './admin'

Vue.use(VueRouter)

// 不用权限的公用页面
export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    component: Layout,
    children: [
      {
        path: '',
        name: 'dashboard',
        meta: {
          icon: 'icon-home',
          title: '首页',
        },
        component: () => import('@/views/Dashboard.vue'),
      },
    ],
  },
]

export const asyncRouterMap = [
  ...electricityRouter,
  ...cdKeyRouter,
  ...betaKeyRouter,
  ...adminRouter,
  {
    path: '/logoff',
    component: () => import('@/views/Layout.vue'),
    meta: { role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'] },
    children: [
      {
        path: '',
        name: 'LogOffManage',
        meta: {
          icon: 'icon-logoff',
          title: '注销申请查询',
        },
        component: () => import('@/views/LogOffManage.vue'),
      },
    ],
  },
  {
    path: '/settings',
    component: () => import('@/views/Layout.vue'),
    meta: { role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'] },
    children: [
      {
        path: '',
        name: 'settings',
        meta: {
          icon: 'el-icon-setting',
          title: '应用配置',
        },
        component: () => import('@/views/Settings.vue'),
      },
    ],
  },
  {
    path: '*',
    hidden: true,
    component: () => import('@/views/404.vue'),
  },
]

export const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: constantRouterMap,
})
