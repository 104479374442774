const cdKeyRouter = [
  {
    path: '/cdKey',
    component: () => import('@/views/Layout.vue'),
    meta: {
      role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'],
    },
    children: [
      {
        path: '',
        name: 'CdKeyManage',
        meta: {
          icon: 'icon-gift',
          title: '兑换码管理',
        },
        component: () => import('@/views/CdKeyManage.vue'),
      },
    ],
  },
]

export default cdKeyRouter
