import { GET, POST, PUT } from './fetch'

// 获取管理员列表
export const getManagerList = params => GET('/m/manager/', params)

// 获取当前用户信息
export const getCurrentUserInfo = () => GET('/m/manager/me/')

// 创建新用户, 需超管身份
export const createManager = params => POST('/m/manager/', params)

// 更新当前用户信息
export const updateMy = params => PUT('/m/manager/', params)

// 更新指定用户信息, 需超管身份
export const updateManager = params => PUT('/m/manager/by_super/', params)
