import axios from 'axios'
import qs from 'qs'
import { Notification, MessageBox } from 'element-ui'
import cookie from '../utils/cookie'
import store from '@/store/index'

// 环境value
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_TARGET : ''

const errMsg = err => {
  const { data, status, config } = err.response
  switch (status) {
    case 401:
      MessageBox.alert('权限验证失败，请重新登录', '', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        store.dispatch('clearUserInfo').then(() => {
          location.reload() // 重新实例化vue-router对象 避免重复定义route
        })
      })
      break
    case 403:
      MessageBox.alert('无权访问该页面', '', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        location.href = '/#/dashboard'
      })
      break
    default:
      Notification({
        title: '请求错误',
        dangerouslyUseHTMLString: true,
        message: `<p style="color:#F56C6C;">请求:${config.url}</p>
      <p>错误码: ${status}</p>
      <p>
        ${data.message ? data.message : data.msg ? data.msg : data.error}
      </p>`,
        type: 'error',
        position: 'top-right',
        duration: 5000,
      })
      break
  }
}

const pending = new Map()

const addPending = config => {
  let identifier = [config.method, config.url, qs.stringify(config.params), qs.stringify(config.data)].join('&')
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pending.has(identifier)) {
        pending.set(identifier, cancel)
      }
    })
}

const removePending = config => {
  let identifier = [config.method, config.url, qs.stringify(config.params), qs.stringify(config.data)].join('&')

  if (pending.has(identifier)) {
    const cancel = pending.get(identifier)
    cancel(identifier)
    pending.delete(identifier)
  }
}

// 请求带上token
axios.interceptors.request.use(
  function(config) {
    removePending(config)
    addPending(config)

    let token = cookie.getToken() || ''
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  function(error) {
    return Promise.reject(new Error(error))
  }
)

axios.interceptors.response.use(
  response => {
    const { status, data, config } = response
    removePending(config)
    if (status === 200) {
      if (data.status === 1) {
        return Promise.resolve(data)
      } else {
        return Promise.reject(data)
      }
    } else {
      return Promise.reject(data)
    }
  },
  error => {
    errMsg(error)
    return Promise.reject(error)
  }
)

export function GET(url, params, config) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params, config })
      .then(response => resolve(response.data))
      .catch(err => reject(err))
  })
}

export function POST(url, data, config) {
  const defaultConfig = {
    credentials: 'include',
  }
  const newConfig = { ...defaultConfig, ...config }
  newConfig.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newConfig.headers,
  }
  if (config && config.useJSON) {
    data = JSON.stringify(data)
  }

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, newConfig)
      .then(response => resolve(response.data))
      .catch(err => reject(err))
  })
}

export function PUT(url, data, config) {
  const defaultConfig = {
    credentials: 'include',
  }
  const newConfig = { ...defaultConfig, ...config }
  newConfig.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newConfig.headers,
  }
  if (config && config.useJSON) {
    data = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, newConfig)
      .then(response => resolve(response.data))
      .catch(err => reject(err))
  })
}

export function PATCH(url, data, config) {
  const defaultConfig = {
    credentials: 'include',
  }
  const newConfig = { ...defaultConfig, ...config }
  newConfig.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newConfig.headers,
  }
  if (config && config.useJSON) {
    data = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, newConfig)
      .then(response => resolve(response.data))
      .catch(err => reject(err))
  })
}

export function DELETE(url, data, config) {
  const defaultConfig = {
    credentials: 'include',
  }
  const newConfig = { ...defaultConfig, ...config }
  newConfig.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newConfig.headers,
  }
  if (config && config.useJSON) {
    data = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    axios
      .delete(url, data, newConfig)
      .then(response => resolve(response.data))
      .catch(err => reject(err))
  })
}
