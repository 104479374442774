import Cookies from 'js-cookie'

export default {
  getToken() {
    return Cookies.get('rm_token')
  },
  clearLoginInfo() {
    Cookies.remove('rm_token')
  },
  getCookie(key) {
    return Cookies.get(key)
  },
  setCookie(params) {
    return new Promise(resolve => {
      for (let val in params) {
        Cookies.set(val, params[val], { expires: 7 })
      }
      resolve()
    })
  },
}
