import Vue from 'vue'
import App from './App.vue'
import { router, asyncRouterMap } from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/assets/styles/theme/index.css'
// import 'element-ui/lib/theme-chalk/index.css' // elementUI 默认白垩主题
import cookie from './utils/cookie'
import filters from './filters'
import { getCurrentUserInfo } from '@/api/admin'
import { USER_ROLES } from '@/views/config'
// import './utils/directives'

Vue.use(ElementUI, { size: 'small' })

Vue.config.productionTip = false

// 注册全局过滤器
for (let key in filters) {
  Vue.filter(key, filters[key])
}

/**
 * 自定义指令, v-permission
 * 自定义组件拦截 click 事件
 * TODO: 原生组件无法拦截 click 事件, 所以只能移除并重新添加, 这样就没有添加的事件了
 */
Vue.directive('permission', {
  inserted: function(el, binding, vnode) {
    let node = el

    if (hasPermission(store.getters.userInfo.roles, binding.value)) {
      return
    }
    // 自定义组件 移除 click 监听
    if (vnode.componentInstance) {
      vnode.componentInstance.$off('click')
    } else {
      node = el.cloneNode(true)
      let parent = el.parentElement

      el.remove()
      parent.appendChild(node)
    }

    node.addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()
      ElementUI.Message({
        type: 'warning',
        message: `暂无权限`,
      })
    })
  },
})

/**
 * 判断是否有相应权限
 * @param {} roleList 用户的角色列表
 * @param {*} needRole 所需权限列表
 */
function hasPermission(roleList, needRole) {
  return roleList.some(role => {
    return needRole.indexOf(role) > -1
  })
}

function hasRoutePermission(roleList, route) {
  if (route.meta && route.meta.role.length > 0) {
    return hasPermission(roleList, route.meta.role)
  } else {
    return true
  }
}

router.beforeEach((to, from, next) => {
  let token = cookie.getToken()
  let hasToken = !!token

  if (hasToken) {
    if (to.path === '/login') {
      let referrer = cookie.getCookie('rm_referrer')
      if (referrer) {
        next({ path: referrer })
      } else {
        next({ path: '/dashboard' })
      }
    } else {
      if (store.getters.userInfo.id === '' || store.getters.route.length === 0) {
        // 获取用户信息
        getCurrentUserInfo()
          .then(res => {
            // 根据 role 生成可访问的路由表
            let roles = res.roles.map(role => USER_ROLES[role].en)
            const addRoute = asyncRouterMap.filter(route => {
              return hasRoutePermission(roles, route)
            })
            router.addRoutes(addRoute)
            store.commit('saveAddRoute', addRoute)
            store.commit('saveUserInfo', res)
            next({
              ...to,
              replace: true,
            })
          })
          .catch(err => {
            console.error(err)
            ElementUI.MessageBox.alert('获取用户信息失败，请重新登录', '', {
              confirmButtonText: '确定',
              type: 'warning',
            }).then(() => {
              store.dispatch('clearUserInfo').then(() => {
                location.reload()
              })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      cookie.setCookie({ rm_referrer: to.fullPath })
      next('/login')
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
