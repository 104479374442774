const electricityRouter = [
  {
    path: '/electricity',
    component: () => import('@/views/Layout.vue'),
    meta: {
      role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'],
      icon: 'icon-vip',
      title: '电商管理',
    },
    children: [
      {
        path: 'inventory',
        name: 'InventoryManage',
        meta: { title: 'inventory管理' },
        component: () => import('@/views/electricity/InventoryManage.vue'),
      },
      {
        path: 'config',
        name: 'GoodsConfig',
        meta: { title: '商品配置' },
        component: () => import('@/views/electricity/GoodsConfig.vue'),
      },
      {
        path: 'order',
        name: 'orderManage',
        meta: {
          title: '订单管理',
        },
        component: () => import('@/views/electricity/OrderManage.vue'),
      },
    ],
  },
]

export default electricityRouter
