const betaKeyRouter = [
  {
    path: '/beta/key',
    component: () => import('@/views/Layout.vue'),
    meta: {
      role: ['ROLE_COMMON_ADMIN', 'ROLE_SUPER'],
    },
    children: [
      {
        path: '',
        name: 'BetaKeyManage',
        meta: {
          icon: 'icon-invite',
          title: '邀请码管理',
        },
        component: () => import('@/views/BetaKeyManage.vue'),
      },
    ],
  },
]

export default betaKeyRouter
