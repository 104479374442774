<template>
  <el-header id="cocoa-header">
    <div class="menu-operation">
      <i class="iconfont icon-menu" :class="isCollapse ? 'rotate' : ''" @click="changeCollapseStatus"></i>
      <bread-crumb class="menu-breadcrumb"></bread-crumb>
    </div>
    <div class="header-operation">
      <el-dropdown v-if="isLogin" @command="handleCommand">
        <div class="user-info">
          <p class="nickname">{{ userInfo.nickname }}</p>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="editCurrentInfo">编辑信息</el-dropdown-item>
          <el-dropdown-item command="logout">账户登出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { USER_ROLES } from '@/views/config'
import cookie from '@/utils/cookie'
import BreadCrumb from '@/components/BreadCrumb'
import EditAdminDrawer from '@/views/Admin/components/EditAdmin/index'

export default {
  data() {
    return {}
  },
  components: {
    BreadCrumb,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      isCollapse: 'isCollapse',
    }),
    userRolesMap: () => USER_ROLES,
    isLogin: function() {
      return cookie.getToken() !== ''
    },
  },
  methods: {
    ...mapActions({
      clearUserInfo: 'clearUserInfo',
      saveUserInfo: 'saveUserInfo',
      changeCollapse: 'changeCollapse',
    }),
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.clearUserInfo().then(() => {
            location.reload() // 清除动态添加的route
          })
          break
        case 'editCurrentInfo':
          EditAdminDrawer({ data: this.userInfo, isEditCurUser: true, onSuccess: data => this.saveUserInfo(data) })
          break
      }
    },
    changeCollapseStatus() {
      this.changeCollapse(!this.isCollapse)
    },
  },
}
</script>

<style lang="scss">
#cocoa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  line-height: 60px;
  z-index: 999;
  .menu-operation {
    display: flex;
    .icon-menu {
      font-size: 22px;
      cursor: pointer;
      transition: all 0.3s;
      &.rotate {
        transform: rotate(90deg);
      }
    }
    .menu-breadcrumb {
      margin-left: 40px;
    }
  }
  .header-operation {
    justify-self: flex-end;
    a {
      margin-right: 20px;
      line-height: 60px;
      color: $text-placeholder;
      text-decoration: none;
      &:hover {
        color: $white;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      .nickname {
        margin-right: 10px;
        color: $text-primary;
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .el-dropdown {
      margin-right: 20px;
      float: right;
      line-height: 60px;
      color: $text-placeholder;
      cursor: pointer;
    }
  }
}
</style>
