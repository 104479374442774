import cookie from '@/utils/cookie'
import { USER_ROLES } from '@/views/config'

const state = {
  token: '',
  route: '',
  userInfo: {
    id: '',
    nickname: '',
    username: '',
    roles: [],
    status: null,
  },
  isCollapse: false,
}

const getters = {
  token: state => state.token,
  route: state => state.route,
  userInfo: state => state.userInfo,
  isCollapse: state => state.isCollapse,
}

const actions = {
  saveUserInfo({ commit }, params) {
    return new Promise(resolve => {
      commit('saveUserInfo', params)
      resolve()
    })
  },
  clearUserInfo({ commit }) {
    return new Promise(resolve => {
      commit('clearUserInfo')
      cookie.clearLoginInfo()
      resolve()
    })
  },
  changeCollapse({ commit }, params) {
    return new Promise(resolve => {
      commit('saveCollapse', params)
      resolve()
    })
  },
}

const mutations = {
  saveToken(state, token) {
    state.token = token
  },
  saveUserInfo(state, userInfo) {
    let roles = userInfo.roles || state.userInfo.roles
    userInfo.roles = roles.map(role => {
      return USER_ROLES[role] && USER_ROLES[role].en !== '' ? USER_ROLES[role].en : role
    })
    state.userInfo = userInfo
  },
  saveAddRoute(state, route) {
    state.route = route
  },
  clearUserInfo(state) {
    state.token = ''
    state.route = []
  },
  saveCollapse(state, isCollapse) {
    state.isCollapse = isCollapse
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
