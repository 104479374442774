<template id="sidebar-item">
  <div v-if="!data.hidden && data.children" ref="SidebarItem">
    <el-menu-item
      v-if="isSingleMenu(data.children)"
      v-show="!singleMenu.meta.hidden"
      :index="path"
      :route="path"
      class="menu-item"
    >
      <i v-if="singleMenu.meta && singleMenu.meta.icon" :class="singleMenu.meta.icon" class="iconfont"></i>
      <span v-if="singleMenu.meta && singleMenu.meta.title" slot="title">{{ singleMenu.meta.title }}</span>
    </el-menu-item>

    <el-submenu v-else :index="path">
      <template slot="title">
        <i v-if="data.meta && data.meta.icon" :class="data.meta.icon" class="iconfont"></i>
        <span v-if="data.meta && data.meta.title && !isCollapse" slot="title">{{ data.meta.title }}</span>
        <!-- 三级菜单 折叠时显示的标题 -->
        <span v-if="data.meta && data.meta.title && child && isCollapse" slot="title">{{ data.meta.title }}</span>
      </template>

      <template v-for="ele in subMenu">
        <template v-if="hasChildMenu(ele)">
          <sidebar-item :key="ele.path" :path="resolvePath(path, ele.path)" :data="ele" child></sidebar-item>
        </template>
        <template v-else>
          <el-menu-item
            v-bind="ele"
            :key="ele.path"
            :index="resolvePath(path, ele.path)"
            :route="resolvePath(path, ele.path)"
          >
            <i v-if="ele.meta && ele.meta.icon" :class="ele.meta.icon" class="iconfont"></i>
            <span v-if="ele.meta && ele.meta.title" slot="title">{{ ele.meta.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SidebarItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    child: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      route: [],
      singleMenu: {},
      subMenu: [],
      subPath: '',
      childMenu: [],
    }
  },
  computed: {
    ...mapGetters({
      isCollapse: 'isCollapse',
    }),
  },
  methods: {
    resolvePath(path, subPath) {
      return path + '/' + subPath
    },
    /**
     * 判断是否是一级菜单
     * @param children {object}
     * @returns {boolean}
     */
    isSingleMenu(children) {
      this.singleMenu = []
      this.subMenu = []
      const showingItem = children.filter(item => {
        return !item.hidden
      })
      if (showingItem.length === 1) {
        this.singleMenu = showingItem[0]
        return true
      } else {
        this.subMenu = _.cloneDeep(showingItem)
        return false
      }
    },
    /**
     * 判断是否有嵌套的多级菜单
     * @param menu {object}
     * @returns {boolean}
     */
    hasChildMenu(menu) {
      if (menu.children && menu.children.length > 0) {
        let children = menu.children.filter(item => !item.hidden)
        this.childMenu = children.length > 0 ? _.cloneDeep(children) : []
        return children.length > 0
      }
      return false
    },
  },
}
</script>

<style></style>
