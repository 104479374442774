<template>
  <el-container>
    <dynamic-aside></dynamic-aside>
    <el-container direction="vertical" :class="['page-container', `${this.isCollapse ? 'collapse' : ''}`]">
      <dt-header></dt-header>
      <el-main class="main-container">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import DynamicAside from '@/components/DynamicAside/index'
import DtHeader from '@/components/DtHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'Layout',
  components: {
    'dynamic-aside': DynamicAside,
    'dt-header': DtHeader,
  },
  computed: {
    ...mapGetters({
      isCollapse: 'isCollapse',
    }),
  },
}
</script>

<style lang="scss">
.page-container {
  margin-left: 180px;
  max-height: 100vh;
  overflow-y: scroll;
  transition: all 200ms;

  &.collapse {
    margin-left: 64px;
  }
}
</style>
