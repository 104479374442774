<template>
  <el-aside id="rm-side" :width="getAsideWidth()">
    <div id="rm-logo">
      <router-link :to="{ name: 'dashboard' }" v-if="!isCollapse">
        <h2>RichMan</h2>
      </router-link>
      <router-link :to="{ name: 'dashboard' }" v-else>
        <h2>RM</h2>
      </router-link>
    </div>
    <el-menu
      :default-active="$route.path"
      router
      mode="vertical"
      :background-color="backgroundDark"
      :active-text-color="activeColor"
      :text-color="grey"
      :collapse="isCollapse"
      :collapse-transition="false"
    >
      <sidebar-item
        v-for="item in route"
        :key="item.name"
        :data="item"
        :path="item.path"
        :collapse="isCollapse"
      ></sidebar-item>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex'
import sidebarItem from './sidebarItem'

export default {
  components: { sidebarItem },
  data() {
    return {
      backgroundDark: '#373a43',
      grey: '#999',
      activeColor: '#FDC86B',
    }
  },
  computed: {
    ...mapGetters({
      route: 'route',
      isCollapse: 'isCollapse',
    }),
  },
  mounted() {},
  methods: {
    getAsideWidth() {
      return this.isCollapse ? '64px' : '180px'
    },
  },
}
</script>

<style lang="scss">
#rm-side {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  color: transparent;
  background: #373a43;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  height: 100vh;
  transition: all 0.2s;
  z-index: 999;

  #rm-logo {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    height: 60px;
    line-height: 60px;
    h2 {
      font-family: 'Didot';
      font-size: 30px;
      letter-spacing: 1px;
      color: #fff;
    }
    img {
      height: 100%;
    }
  }
  //a {
  //  text-decoration: none;
  //}
  .el-menu {
    border: none !important;
    user-select: none;
  }
  .el-menu--inline {
    .el-menu-item {
      padding-left: 50px !important;
      background: transparent;
      color: #fff;
    }
  }
  .el-menu-item,
  .el-submenu__title {
    padding-left: 30px;
    .iconfont {
      margin-right: 8px;
      line-height: 19px;
      vertical-align: text-top;
      font-size: 18px;
    }
  }
  .el-menu--collapse {
    .el-submenu__title.title {
      display: none;
    }
    .el-submenu__icon-arrow {
      margin-top: -6.5px;
      right: 15px;
      font-size: 14px;
    }
  }
}
</style>
