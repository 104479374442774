import Vue from 'vue'
import drawer from './EditAdminDrawer'

const editConstructor = Vue.extend(drawer)

const EditAdminDrawer = function(options) {
  if (Vue.prototype.$isServer) return
  options = options || {}

  const instance = new editConstructor()
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.open(options)
  return instance.vm
}

export default EditAdminDrawer
