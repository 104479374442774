<template>
  <div class="bread-nav">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-if="breadList.navTitle">{{ breadList.navTitle }}</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in breadList.list" :key="index" :to="{ path: item.path }">
        {{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  name: 'BreadCrumb',
  data() {
    return {
      breadList: {
        navTitle: '',
        list: [],
      },
    }
  },

  watch: {
    $route() {
      this.handleBreadList()
    },
  },
  mounted() {
    this.handleBreadList()
  },
  methods: {
    handleBreadList() {
      let matched = this.$route.matched
      this.breadList.navTitle = matched[0].meta.title
      this.breadList.list = matched.slice(1)
    },
  },
}
</script>
<style lang="scss">
.el-breadcrumb {
  line-height: 60px !important;
}
</style>
